<template>
  <div>
    <l-marker
        v-for="(marker, index) in markers"
        :key="index"
        :visible="true"
        :draggable="false"
        :lat-lng="{lat: marker.lat, lng: marker.lon}"
        @click="markerClick(marker)"
      >
      <l-icon v-if="marker.selected && marker.status === 'approved'">
        <b-img
          :src="require('@/assets/images/locations/accept.png')"
          height="25"
        />
      </l-icon>
      <l-icon v-if="marker.selected && marker.status === 'running'">
        <b-img
          :src="require('@/assets/images/locations/running.png')"
          height="25"
        />
      </l-icon>
      <l-tooltip :content="marker.address" />
    </l-marker>
  </div>
</template>

<script>
import {
  LMarker,
  LTooltip,
} from 'vue2-leaflet';
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
export default {
  name: 'markersMap',
  components: {
    LMarker,
    LTooltip
  },
  props: {
    markers: {
      type: Array,
    }
  },
  methods: {
    markerClick(marker) {
      this.$emit('marker_click', marker);
    }
  }
}
</script>

<style>

</style>