<template>
  <div class="pt-10" v-if="show_map_template">
    <leaflet-basic v-if="false"/>
    <l-map 
      :zoom.sync="zoom"
      :options="mapOptions"
      :bounds="bounds"
      style="height: 100vh; width: 100%"
      ref="map"
      :key="update_map"
    >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <markers-map 
          :markers="Object.keys(marker_selected).length > 0 ? [marker_selected] : markers_query" 
          @marker_click="zoomMarker"
        />
      <l-control-zoom position="bottomright" />
    </l-map>
    
    <div class="container-searcher-map">
      <b-button 
        class="return-action-map d-flex align-items-center" 
        @click="backAction()"
        :disabled="disabled_return"
      >
        <feather-icon icon="ArrowLeftIcon" size="25"/>        
      </b-button>
      <b-card class="mr-0" id="card-search-by">
        <b-input-group>
          <b-form-input 
              class="input-search-by-influencer d-block" 
              :placeholder="$t('search.realty.placeholder_search')"
              v-model="query"
              @keyup.enter="searchAction()"  
          ></b-form-input>
          <b-input-group-append>
            <div class="d-flex align-items-center cursor-pointer px-02" v-if="query !== null" @click="deleteQuery()">
              <feather-icon icon="XIcon"/>
            </div>
            <div class="search-icon-map border-left" @click="searchAction()">
              <feather-icon icon="SearchIcon" size="20" v-if="!loading"/>
              <b-spinner v-if="loading" class="loading-search-icon-map"></b-spinner>
            </div>
          </b-input-group-append>
        </b-input-group>
   
      </b-card>
    </div>
    
    <b-card class="card-locations-overmap" ref="card_locations_overmap">
      <b-row class="m-0 height-scroll-maps" ref="scrollable" @scroll="checkScroll()"> 
        <div class="row m-0" v-if="markers_query.length === 0">
          <b-col class="p-2">
            <h2>No hay ubicaciones relacionadas con <strong>"{{query}}"</strong></h2>
          </b-col>
        </div>
        <list-locations
          :markers="markers_query" 
          :campaigns="campaigns"
          :marker_selected="marker_selected"
          :location_in="location_in"
          :update_drop_campaigns="update_drop_campaigns"
          @zoom_marker="zoomMarker"
          @update_location_in="getLocationIn"
        />
        <b-col class="d-flex justify-content-center" v-if="loading">
          <b-spinner class="spinner-list-locations"/>
        </b-col>
      </b-row>
      <div class="card-locations-collapse-button" @click="collapseLocations">
        <feather-icon :icon="!open_collapse ? 'ChevronLeftIcon' : 'ChevronRightIcon'" size="25"></feather-icon>
      </div>
    </b-card>

    <filters-map @filter_map="filterMap"/>
  </div>
</template>

<script>

import {
  LMap,
  LTileLayer,
  LControlZoom,
} from 'vue2-leaflet';
import { 
  BCard,
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
  BButton,
} from 'bootstrap-vue';
import service_ooh from '@/services/outofhome.js';
import service_campaign from '@/services/dashboard';
import { latLngBounds } from 'leaflet';
import LeafletBasic from '@/views/charts-and-maps/maps/leaflet/LeafletBasic.vue';
import listLocations from '@/views/pages/outofhome/maps/listLocations.vue';
import markersMap from '@/views/pages/outofhome/maps/markersMap.vue';
import filtersMap from '@/views/pages/outofhome/maps/filtersMap.vue';

export default {
  components: {
    LMap,
    LTileLayer,
    LeafletBasic,
    LControlZoom,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    BButton,
    markersMap,
    filtersMap,
    listLocations,
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:'&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      center: [51.505, -0.159],
      markerLatLng: [51.504, -0.159],
      mapOptions: {
        zoomControl: false,
        attributionControl: false,
        zoomSnap: true,
      },
      bounds: latLngBounds(
        { lat: 14.1303191397, lng: -117.0556529104 },
        { lat: 32.5803068469, lng: -86.030154997 }
      ),
      markers_query: [],
      marker_selected: {},
      query: null,
      update_map: false,
      loading: false,
      show_map_template: false,
      open_collapse: false,
      location_in: [],
      disabled_return: true,
      update_drop_campaigns: false,
      current_page: 1,
      total_count_query: 0,
      campaigns: [],
      availability: {},
      image: {},
      category: {},
      state: {},
    }
  },
  created() {
    this.getLocations(false, true);
    this.getCampaigns();
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
    this.$store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: 'hidden' });
    setTimeout(() => {
      const body = document.querySelector('div.app-content.content');
      const header_navbar_shadow = document.getElementsByClassName('header-navbar-shadow')[0];
      body.style.setProperty('padding', '0px',  'important');
      header_navbar_shadow.style.setProperty('display', 'none');
    }, 1000);
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false);
    this.$store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: 'static' });
    const body = document.querySelector('div.app-content.content');
    const header_navbar_shadow = document.getElementsByClassName('header-navbar-shadow')[0];
    header_navbar_shadow.removeAttribute('style')
    body.removeAttribute('style');
  },
  methods: {
    searchAction() {
      this.current_page = 1;
      this.getLocations();
      this.scrollUp();
    },
    checkScroll() {
      const scroll = this.$refs.scrollable.offsetHeight + this.$refs.scrollable.scrollTop;
      if ((scroll >= this.$refs.scrollable.scrollHeight && this.markers_query.length < this.total_count_query) && !this.loading) {
        this.current_page += 1;
        this.getLocations(true);
      }
    },
    zoomMarker(marker) {
      this.marker_selected = marker;
      this.getLocationIn(this.marker_selected.uuid);
      this.$refs.map.mapObject.setView({lat: marker.lat,lng: marker.lon}, 150);
      this.scrollUp();
      this.disabled_return = false;  
    },
    getCampaigns() {
      service_campaign.getCampaign().then((response) => {
        response.response.results.filter((item) => {
          const obj = {value:item.uuid, text: item.name}
          this.campaigns.push(obj)
        });
      });
    },
    getLocationIn(location_uuid) {
      service_ooh.getLocationIn(location_uuid).then((response) => {
        this.location_in = [];
        response.results.map((item) => {
          this.location_in.push({campaign_name: item.campaign_name, uuid: item.campaign_uuid, location_uuid: item.location_uuid})
        });
        this.update_drop_campaigns = !this.update_drop_campaigns;
      });
    },
    getLocations(is_concat = false, is_initial = false) {
      this.loading = true;
      service_ooh.searchLocations(this.getFilters(), this.current_page).then((response) => {
        (is_concat) ? this.markers_query = this.markers_query.concat(response.results) : this.markers_query = response.results;
        if (!is_initial) {
          if (this.markers_query.length !== 0 && !is_concat) 
            this.$refs.map.mapObject.setView({lat: this.markers_query[0].lat,lng: this.markers_query[0].lon}, 5);
          if (this.markers_query.length === 0) this.marker_selected = {};
        }
        this.total_count_query = response.count;
        setTimeout(() => {
          this.loading = false;
          this.show_map_template = true;
        }, 200);
      });
    },
    getFilters() {
      const filters = {};
      const data = {
        query: this.query,
        availability: this.availability.value,
        image: this.image.value,
        category: this.category.value,
        state: this.state.value
      }
      const dataKeys = Object.keys(data)
      for (let i = 0; i < dataKeys.length; i++) {
        const dataKey = dataKeys[i]
        if ((data[dataKey] !== null)) {
          filters[dataKey] = data[dataKey]
        }
      }
      return filters
    },
    deleteQuery() {
      this.query = null; 
    },
    collapseLocations() {
      this.open_collapse = !this.open_collapse;
      if (this.open_collapse) this.$refs.card_locations_overmap.classList.add('card-locations-overmap-hidden');
      else this.$refs.card_locations_overmap.classList.remove('card-locations-overmap-hidden');
    },
    scrollUp() {
      this.$refs.scrollable.scrollTop = 0;
      this.disabled_return = false;
    },
    backAction() {
      this.scrollUp();
      this.update_map = !this.update_map;
      if (Object.keys(this.marker_selected).length > 0) {
        this.marker_selected = {}; 
      } else {
        this.query = null;
        this.getLocations(false, true);
        this.disabled_return = true;
      }
    },
    filterMap(options, name) {
      this[name] = options;
      this.searchAction();
    }
  },
}
</script>

<style>
.height-scroll-maps {
  height: 100%;
  max-height: 100% !important;
  overflow: scroll;
}
.scrollable-location-maps {
  height: 100%;
  overflow-y: scroll;
}
.px-02 {
  padding: 0 0.2em;
}
.pt-10 {
  padding-top: 0em !important;
}
.card-locations-overmap {
  width: 400px;
  height: 80%;
  position: absolute;
  top: 10em;
  left: 2em;
  z-index: 1000;
}
.card-locations-overmap-hidden {
  left: -28.7em;
  animation: animation-location 1s;
}
.card-locations-collapse-button {
  background-color: #eeeeee;
  position: absolute;
  top: 45%;
  padding: 1em 0;
  right: -1.8em;
  z-index: 1000;
  border-top-right-radius: 0.7em;
  border-end-end-radius: 0.7em;
  cursor: pointer;
}
.card-locations-overmap > .card-body {
  padding: 0 !important;
}
.badge-map {
  font-size: 10px ;
  background-color: red;
  position: absolute;
  z-index: 5;
  top: 0.5em;
  left: 0.5em;
}
/* Zoom actions map */
.leaflet-right .leaflet-control {
  margin-bottom: 9em;
  margin-right: 2.8em;
}
.btn-scroll-to-top {
  right: -10px !important;
}
/* ----- */
/* Card selected marker */
.badge-selected {
  font-size: 14px;
}
/*  */
/* Container searcher */
.container-searcher-map {
  position: absolute;
  z-index: 100;
  top: 6em;
  left: 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
}
.return-action-map {
  color: #444 !important;
  background-color: white !important;
  border: 0px !important;
  padding: 0.7em;
  border-radius: 100%;
  cursor: pointer;
}
.return-action-map:disabled {
  background-color: rgba(255, 255, 255, 0.377) !important;
  color: rgba(68, 68, 68, 0.37) !important;
  cursor: auto;
}
.return-action-map:focus {
  background-color: white !important;
}
#card-search-by {
  width: 340px;
  margin: 0 !important;
  display: flex;
}
#card-search-by .card-body {
  padding: 0 0 0 0.5em;
  margin: 0 !important;
}
.input-search-by-influencer {
  width: 70%;
  border: none ;
  box-shadow: none;
}
.input-search-by-influencer:focus {
  box-shadow: none !important;
}
.search-icon-map {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ccc;
  cursor: pointer;
}
.loading-search-icon-map {
  width: 20px;
  height: 20px;
}
.animation-locations {
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/map-leaflet.scss';
</style>